// src/utils/alerts.ts
import Swal from "sweetalert2";

export const showAlert = (
  title: string,
  text: string,
  icon: "success" | "error" | "warning" | "info" = "info"
) => {
  return Swal.fire({
    title,
    text,
    icon,
    confirmButtonColor: "#28a745",
    confirmButtonText: "OK",
  });
};

export const showConfirm = (
  title: string,
  text: string,
  icon: "warning" | "question" = "question"
) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonText: "Yes",
    confirmButtonColor: "#28a745",
    cancelButtonColor: "#dc3545",
    cancelButtonText: "No",
  });
};

export const showPrompt = (
  title: string,
  text: string,
  inputValue: string = ""
) => {
  return Swal.fire({
    title,
    text,
    input: "textarea",
    inputValue,
    showCancelButton: true,
    confirmButtonText: "Save",
    confirmButtonColor: "#28a745",
    cancelButtonColor: "#dc3545",
    cancelButtonText: "Cancel",
    inputValidator: (value) => {
      if (!value) {
        return "You need to write something!";
      }
      return null;
    },
  });
};
