import React, { useState } from "react";
import styled from "styled-components";
import { Transaction } from "../../Constants/interfaces";
import { EDIParser } from "./highboundParser";

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Arial", sans-serif;
`;

const PageTitle = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #34495e;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const FileInput = styled.input`
  margin-bottom: 1rem;
`;

const UploadButton = styled.button`
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 0.5rem;
  text-align: left;
  background-color: #f2f2f2;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid #ecf0f1;
`;

const TransactionsParser: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [expandedTransaction, setExpandedTransaction] = useState<string | null>(
    null
  );

  const parseFile = (f: File): Promise<any> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          let ediFile = e.target?.result as string;
          resolve(ediFile);
        } catch (er) {
          reject(er);
        }
      };
      reader.readAsText(f);
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      const ediTransactions = await parseFile(file);
      console.log(ediTransactions);
      let trans = await new EDIParser().parse(ediTransactions);
      setTransactions([...trans]);
      console.log(trans);
      setFile(null);
    } catch (er) {
      console.error(er);
    } finally {
      setFile(null);
    }
  };

  const toggleTransaction = (claimId: string) => {
    if (expandedTransaction === claimId) {
      setExpandedTransaction(null);
    } else {
      setExpandedTransaction(claimId);
    }
  };

  return (
    <PageContainer>
      <PageTitle>Transaction Upload</PageTitle>
      <Section>
        <SectionTitle>Upload Transactions</SectionTitle>
        <FileInput type="file" accept=".edi,.CLM" onChange={handleFileChange} />
        <UploadButton onClick={handleUpload} disabled={!file}>
          Parse
        </UploadButton>
      </Section>
      <Section>
        <SectionTitle>Current Transactions Details</SectionTitle>
        <Table>
          {transactions.map((transaction) => (
            <React.Fragment key={transaction.claim_h_uuid}>
              <thead>
                <tr>
                  <Th>Sender Id</Th>
                  <Th>Receiver_Id</Th>
                  <Th>claim_h_uuid</Th>
                  <Th>Transaction Date</Th>
                  <Th>Transaction Type</Th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={() => toggleTransaction(transaction.claim_h_uuid)}>
                  <Td>{transaction.sender_id}</Td>
                  <Td>{transaction.receiver_id}</Td>
                  <Td>{transaction.claim_h_uuid}</Td>
                  <Td>{transaction.transaction_date}</Td>
                  <Td>{transaction.transaction_type}</Td>
                </tr>
                {expandedTransaction === transaction.claim_h_uuid && (
                  <tr>
                    <td colSpan={5}>
                      <Table>
                        <tbody>
                          {transaction.segments.map((segment) => (
                            <React.Fragment key={segment.segment_id}>
                              <tr>
                                <Td>
                                  <b>{segment.segment_id}</b>
                                </Td>
                                <Td>
                                  <b>{segment.segment_name}</b>
                                </Td>
                                <Td
                                  style={{
                                    color: segment.containsPHI
                                      ? "red"
                                      : "green",
                                  }}
                                >
                                  <b>{`Contains PHI: ${segment.containsPHI}`}</b>
                                </Td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <Table>
                                    <tbody>
                                      {Object.entries(segment.data).map(
                                        ([key, value]) => (
                                          <tr key={key}>
                                            <Td>{key}</Td>
                                            <Td>{value}</Td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                )}
              </tbody>
            </React.Fragment>
          ))}
        </Table>
      </Section>
    </PageContainer>
  );
};

export default TransactionsParser;
