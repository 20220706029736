// components/ChatBot/ChatBot.tsx
import ReactMarkdown from "react-markdown";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { authFetch } from "../../Utils/authFetch";
import {
  ChatbotProps,
  ChatHistory,
  ChatHistoryType,
  ScreenStateInterface,
  UIActionInterface,
  UIActionSelectionInterface,
} from "../../Constants/interfaces";
import { useChatContext } from "../../Contexts/ChatContext";

const ChatbotContainer = styled.div<{ isopen: boolean }>`
  position: fixed;
  right: ${({ isopen }) => (isopen ? "0" : "-400px")};
  top: 80px;
  bottom: 0;
  width: 400px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ToggleButton = styled.button<{ isopen: boolean }>`
  position: fixed;
  right: ${({ isopen }) => (isopen ? "400px" : "0")};
  top: 50%;
  transform: translateY(-50%);
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  z-index: 1001;
`;

const NewChatButton = styled.button`
  background-color: white;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

// Update the ChatHeader styled component
const ChatHeader = styled.div`
  background-color: #28a745;
  color: white;
  padding: 15px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const MessageContent = styled.div`
  line-height: 1.5;

  p {
    margin: 0.5em 0;
    padding: 0;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  code {
    background-color: #f0f0f0;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    font-family: monospace;
  }

  pre {
    background-color: #f0f0f0;
    padding: 0.5em;
    border-radius: 5px;
    overflow-x: auto;
    margin: 4px 0;
  }

  ul,
  ol {
    margin: 4px 0;
    padding-left: 1.5em;

    li {
      margin: 2px 0;
    }
  }

  ol > li::marker {
    font-weight: bold;
  }

  blockquote {
    border-left: 3px solid #ccc;
    margin: 4px 0;
    padding-left: 8px;
    color: #666;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0 4px 0;
    line-height: 1.2;
  }
`;

const MessageRenderer = styled.div<{ isuser: boolean }>`
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ isuser }) => (isuser ? "#e3f2fd" : "#f5f5f5")};
  align-self: ${({ isuser }) => (isuser ? "flex-end" : "flex-start")};
  margin-bottom: 8px;
`;

const InputContainer = styled.div`
  padding: 15px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
`;

const SendButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
  }
`;
interface Message {
  content: string;
  isUser: boolean;
}

const ChatBot: React.FC<ChatbotProps> = ({ screenState, onActionSelected }) => {
  // Get chat context
  const {
    chatHistory,
    addToChatHistory,
    currentScreenState,
    clearChatHistory,
    setCurrentScreenState,
    isOpen,
    setIsOpen,
  } = useChatContext();

  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>(
    chatHistory.map((entry) => ({
      content: entry.user_facing_messsage,
      isUser: entry.type === ChatHistoryType.user,
    }))
  );

  // Update current screen state when it changes
  useEffect(() => {
    setCurrentScreenState(screenState);
  }, [screenState, setCurrentScreenState]);

  const handleNewChat = () => {
    clearChatHistory();
    setMessages([]);
  };

  const handleSend = async () => {
    console.log(screenState);
    console.log(chatHistory);
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    setInput("");

    // Add user message to messages for display
    setMessages((prev) => [...prev, { content: userMessage, isUser: true }]);

    // Add user message to chat history
    addToChatHistory({
      type: ChatHistoryType.user,
      user_facing_messsage: userMessage,
      content: userMessage,
      timestamp: Date.now(),
    });

    setIsLoading(true);

    try {
      const response = await authFetch(
        "/chat",
        {
          method: "POST",
          body: JSON.stringify({
            message: userMessage,
            chatHistory,
            screenState: currentScreenState,
          }),
        },
        true,
        true
      );

      if (!response.ok) {
        throw new Error("Failed to get response");
      }

      // Add initial assistant message
      setMessages((prev) => [...prev, { content: "", isUser: false }]);

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("No response body");
      }

      const decoder = new TextDecoder();
      let currentMessage = "";
      let visibleMessage = "";
      let totalStream = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        totalStream += chunk;
        const lines = totalStream.split("data: ");
        currentMessage = lines.join("");
        // console.log(currentMessage);

        // ok so we want to strip out [UI_ACTION] stuff and handle it

        if (currentMessage.includes("[UI_ACTIONS]")) {
          visibleMessage = currentMessage.split("[UI_ACTIONS]")[0];
        } else {
          visibleMessage = currentMessage;
        }

        if (visibleMessage.includes("[DATA_LOOKUPS]")) {
          if (!visibleMessage.includes("[END_DATA_LOOKUPS]")) {
            visibleMessage = visibleMessage.split("[DATA_LOOKUPS]")[0];
            if (visibleMessage.length === 0) {
              visibleMessage = "Searching....";
            }
          } else {
            // just remove everything between the data lookups
            // can we parse them?
            let namesOfDataLookups = "Data Sources";
            try {
              let dataLookupStr = visibleMessage
                .split("[DATA_LOOKUPS]")[1]
                .split("[END_DATA_LOOKUPS]")[0];

              let parsed: any[] = JSON.parse(dataLookupStr);
              if (parsed.length > 0) {
                namesOfDataLookups = parsed
                  .map((item) => item.dataSourceName.replace("Search-", ""))
                  .join(", ");
              }
            } catch (error) {
              //  console.error("Error parsing data lookups:", error);
            }

            visibleMessage =
              visibleMessage.split("[DATA_LOOKUPS]")[0] +
              `Searched ${namesOfDataLookups} \n\n` +
              visibleMessage.split("[END_DATA_LOOKUPS]")[1];
          }
        }

        setMessages((prev) => {
          const newMessages = [...prev];
          newMessages[newMessages.length - 1].content = visibleMessage;
          return newMessages;
        });
      }

      // need to maybe add in dataSourceMessages
      if (
        currentMessage.includes("[DATA_LOOKUPS]") &&
        currentMessage.includes("[END_DATA_LOOKUPS]")
      ) {
        // just remove everything between the data lookups
        // can we parse them?
        try {
          let dataLookupStr = currentMessage
            .split("[DATA_LOOKUPS]")[1]
            .split("[END_DATA_LOOKUPS]")[0];

          let parsed: any[] = JSON.parse(dataLookupStr);
          if (parsed.length > 0) {
            let namesOfDataLookups = parsed
              .map((item) => item.dataSourceName.replace("Search-", ""))
              .join(", ");
            let namesOfDataLookupsWithParams = parsed
              .map((item) => {
                return `${item.dataSourceName.replace(
                  "Search-",
                  ""
                )} with parameters: ${JSON.stringify(item.parameters)}`;
              })
              .join(", ");
            let chatHistoryUserContent = `Searched ${namesOfDataLookups}`;
            let chatHistoryContent = `Searched ${namesOfDataLookupsWithParams} \n\n The results have bee removed from the chat history for brevity.`;

            addToChatHistory({
              type: ChatHistoryType.assistant,
              user_facing_messsage: chatHistoryUserContent,
              content: chatHistoryContent,
              timestamp: Date.now(),
            });
          }
        } catch (error) {
          console.error("Error parsing data lookups:", error);
        }
      }

      // Add assistant message to chat history
      // remove the data lookups from the current message so it doesn't get confused with formatting.
      if (currentMessage.includes(`[END_DATA_LOOKUPS]`)) {
        currentMessage = currentMessage.split(`[END_DATA_LOOKUPS]`)[1]; // just take the rest of the message after
      }

      addToChatHistory({
        type: ChatHistoryType.assistant,
        user_facing_messsage: visibleMessage,
        content: currentMessage,
        timestamp: Date.now(),
      });

      if (currentMessage.includes("[UI_ACTIONS]")) {
        const actionText = (
          currentMessage.split("[UI_ACTIONS]")[1] || ""
        ).split("[END_UI_ACTIONS]")[0];
        try {
          const uiActionSelectionArray: UIActionSelectionInterface[] =
            JSON.parse(actionText);
          // let's find the action

          for (let a = 0; a < uiActionSelectionArray.length; a++) {
            let uiActionSelection = uiActionSelectionArray[a];
            const uiAction = screenState.screenActions.find(
              (action) => action.actionName === uiActionSelection.actionName
            );
            if (uiAction) {
              // merge in parameters
              // first make deep copy
              const newUiAction: UIActionInterface = JSON.parse(
                JSON.stringify(uiAction)
              );
              // then merge in parameters
              newUiAction.parameters = newUiAction.parameters.map((param) => {
                if (
                  Object.keys(uiActionSelection?.parameters || {}).includes(
                    param.parameterName
                  )
                ) {
                  const selectedValue =
                    uiActionSelection.parameters[param.parameterName];
                  if (selectedValue) {
                    return {
                      ...param,
                      selectedValue,
                    };
                  }
                }
                return param;
              });
              let chatHistoryResult = await onActionSelected(newUiAction);
              addToChatHistory(chatHistoryResult);
              setMessages((prev) => [
                ...prev,
                {
                  content: chatHistoryResult.user_facing_messsage,
                  isUser: false,
                },
              ]);
            } else {
              throw new Error(
                `UI Action not found: ${uiActionSelection.actionName}`
              );
            }
          }
        } catch (error: any) {
          console.error("Error parsing UI action:", error);
          let user_facing_messsage = `Error running UI Action: ${actionText} - ${
            error?.message || ""
          }`;
          addToChatHistory({
            type: ChatHistoryType.assistant,
            user_facing_messsage,
            content: `${user_facing_messsage}, Error: ${JSON.stringify(
              error.message || ""
            )}`,
            timestamp: Date.now(),
          });
          setMessages((prev) => [
            ...prev,
            { content: user_facing_messsage, isUser: false },
          ]);
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          content: "Sorry, something went wrong. Please try again.",
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      <ToggleButton isopen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "→" : "←"}
      </ToggleButton>
      <ChatbotContainer isopen={isOpen}>
        <ChatHeader>
          <span>Highbound Assistant</span>
          <ButtonContainer>
            <NewChatButton onClick={handleNewChat}>New Chat</NewChatButton>
          </ButtonContainer>
        </ChatHeader>
        <ChatMessages>
          {messages.map((message, index) => (
            <MessageRenderer key={index} isuser={message.isUser}>
              <MessageContent>
                {message.isUser ? (
                  message.content
                ) : (
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                )}
              </MessageContent>
            </MessageRenderer>
          ))}
          <div ref={messagesEndRef} />
        </ChatMessages>
        <InputContainer>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <SendButton
            onClick={handleSend}
            disabled={isLoading || !input.trim()}
          >
            {isLoading ? "..." : "Send"}
          </SendButton>
        </InputContainer>
      </ChatbotContainer>
    </>
  );
};

export default ChatBot;
