// Pages/Analytics/AnalyticsPage.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { authFetch } from "../../Utils/authFetch";
import { Bar, Pie, Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import Modal from "../../components/Modal";
import { ClaimAnalysis } from "../../Constants/interfaces";

ChartJS.register(...registerables);

const Container = styled.div`
  padding: 40px;
  background: #f5f7fa;
  min-height: calc(100vh - 104px);
  padding-top: 100px;
`;

const Title = styled.h1`
  margin-bottom: 30px;
  color: #2c3e50;
  text-align: center;
`;

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 30px;
`;

const ChartContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 80px;
  margin: 20px;
  max-height: 15vw;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

const ChartTitle = styled.h2`
  margin-bottom: 20px;
  color: #34495e;
  font-size: 1.2rem;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 104px);
  font-size: 1.2rem;
  color: #2c3e50;
`;

const ErrorContainer = styled(LoadingContainer)`
  color: #e74c3c;
`;

const ModalChartContainer = styled.div`
  max-height: 60vh;
  width: 100%;
  margin-bottom: 40px;
`;

const AnalyticsPage: React.FC = () => {
  const [claimAnalyses, setClaimAnalyses] = useState<ClaimAnalysis[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [modalContent, setModalContent] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [analysesData] = await Promise.all([
          authFetch("/claim-analysis"),
        ]);
        setClaimAnalyses(analysesData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingContainer>Loading analytics...</LoadingContainer>;
  }

  if (error) {
    return (
      <ErrorContainer>Error loading analytics: {error.message}</ErrorContainer>
    );
  }

  const getUniqueStatuses = (analyses: ClaimAnalysis[]) => {
    return [...new Set(analyses.map((analysis) => analysis.claim_status))];
  };

  const getRandomColor = () => {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  };

  const claimStatusData = (() => {
    const statuses = getUniqueStatuses(claimAnalyses);
    return {
      labels: statuses,
      datasets: [
        {
          label: "Claims by Status",
          data: statuses.map(
            (status) =>
              claimAnalyses.filter((c) => c.claim_status === status).length
          ),
          backgroundColor: statuses.map(() => getRandomColor()),
        },
      ],
    };
  })();

  const moneyData = {
    labels: ["Charged", "Allowed", "Paid"],
    datasets: [
      {
        label: "Money by Type",
        data: [
          claimAnalyses.reduce((sum, claim) => sum + claim.charge_amount, 0),
          claimAnalyses.reduce((sum, claim) => sum + claim.allowed_amount, 0),
          claimAnalyses.reduce((sum, claim) => sum + claim.paid_amount, 0),
        ],
        backgroundColor: ["#3498db", "#2ecc71", "#e74c3c"],
      },
    ],
  };

  const monthlyClaimsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Submitted Claims",
        data: Array(12)
          .fill(0)
          .map(
            (_, i) =>
              claimAnalyses.filter(
                (c) => new Date(c.transaction_date).getMonth() === i
              ).length
          ),
        borderColor: "#3498db",
        backgroundColor: "rgba(52, 152, 219, 0.2)",
        fill: true,
      },
      {
        label: "Approved Claims",
        data: Array(12)
          .fill(0)
          .map(
            (_, i) =>
              claimAnalyses.filter(
                (c) =>
                  new Date(c.transaction_date).getMonth() === i &&
                  c.claim_status === "Processed"
              ).length
          ),
        borderColor: "#2ecc71",
        backgroundColor: "rgba(46, 204, 113, 0.2)",
        fill: true,
      },
    ],
  };

  const topDenialReasonsData = {
    labels: [
      ...new Set(
        claimAnalyses
          .filter((c) => c.rejection_reason !== "N/A")
          .map((c) => c.rejection_reason)
      ),
    ].slice(0, 5),
    datasets: [
      {
        label: "Denial Reasons",
        data: [
          ...new Set(
            claimAnalyses
              .filter((c) => c.rejection_reason !== "N/A")
              .map((c) => c.rejection_reason)
          ),
        ]
          .slice(0, 5)
          .map(
            (reason) =>
              claimAnalyses.filter((c) => c.rejection_reason === reason).length
          ),
        backgroundColor: [
          "#3498db",
          "#2ecc71",
          "#e74c3c",
          "#f39c12",
          "#9b59b6",
        ],
      },
    ],
  };

  const openModal = (content: React.ReactNode) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <Container>
      <Title>Claims Analytics Dashboard</Title>
      <ChartGrid>
        <ChartContainer
          onClick={() =>
            openModal(
              <ModalChartContainer>
                <Bar
                  data={claimStatusData}
                  options={{ responsive: true, maintainAspectRatio: false }}
                />
              </ModalChartContainer>
            )
          }
        >
          <ChartTitle>Claims by Status</ChartTitle>
          <Bar
            data={claimStatusData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
        <ChartContainer
          onClick={() =>
            openModal(
              <ModalChartContainer>
                <Pie
                  data={moneyData}
                  options={{ responsive: true, maintainAspectRatio: false }}
                />
              </ModalChartContainer>
            )
          }
        >
          <ChartTitle>Money by Type</ChartTitle>
          <Pie
            data={moneyData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
        <ChartContainer
          onClick={() =>
            openModal(
              <ModalChartContainer>
                <Line
                  data={monthlyClaimsData}
                  options={{ responsive: true, maintainAspectRatio: false }}
                />
              </ModalChartContainer>
            )
          }
        >
          <ChartTitle>Monthly Claims Trend</ChartTitle>
          <Line
            data={monthlyClaimsData}
            options={{ responsive: true, maintainAspectRatio: false }}
          />
        </ChartContainer>
        <ChartContainer
          onClick={() =>
            openModal(
              <ModalChartContainer>
                <Bar
                  data={topDenialReasonsData}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    indexAxis: "y",
                  }}
                />
              </ModalChartContainer>
            )
          }
        >
          <ChartTitle>Top Denial Reasons</ChartTitle>
          <Bar
            data={topDenialReasonsData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              indexAxis: "y",
            }}
          />
        </ChartContainer>
      </ChartGrid>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {modalContent}
      </Modal>
    </Container>
  );
};

export default AnalyticsPage;
