import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BASE_API_URL } from "../../Constants/constants";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const LoginCard = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const LoginTitle = styled.h1`
  color: #28a745;
  margin-bottom: 1.5rem;
`;

const LoginButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #218838;
  }
`;

const Login: React.FC = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = `${BASE_API_URL}/login`;
  };

  return (
    <LoginContainer>
      <LoginCard>
        <LoginTitle>Welcome to Highbound Health</LoginTitle>
        <LoginButton onClick={handleLogin}>Login with Google</LoginButton>
      </LoginCard>
    </LoginContainer>
  );
};

export default Login;
