import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from "react";
import { ChatHistory, ScreenStateInterface } from "../Constants/interfaces";

interface ChatContextType {
  chatHistory: ChatHistory[];
  addToChatHistory: (entry: ChatHistory) => void;
  clearChatHistory: () => void;
  currentScreenState: ScreenStateInterface | null;
  setCurrentScreenState: (state: ScreenStateInterface) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [chatHistory, setChatHistory] = useState<ChatHistory[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentScreenState, setCurrentScreenState] =
    useState<ScreenStateInterface | null>(null);

  // Memoize the callback functions
  const addToChatHistory = useCallback((entry: ChatHistory) => {
    setChatHistory((prev) => [...prev, entry]);
  }, []);

  const clearChatHistory = useCallback(() => {
    setChatHistory([]);
  }, []);

  // Memoize the context value
  const value = useMemo(
    () => ({
      chatHistory,
      addToChatHistory,
      clearChatHistory,
      currentScreenState,
      setCurrentScreenState,
      isOpen,
      setIsOpen,
    }),
    [
      chatHistory,
      addToChatHistory,
      clearChatHistory,
      currentScreenState,
      setCurrentScreenState,
      isOpen,
      setIsOpen,
    ]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
