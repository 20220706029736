import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { authFetch } from "../Utils/authFetch";
import { ClaimNotes, NotesProps } from "../Constants/interfaces";

const NotesContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #f9f9f9;
`;

const NoteItem = styled.div`
  background: white;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
`;

const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9em;
  color: #666;
`;

const NoteContent = styled.p`
  margin: 0;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const NoteInput = styled.textarea`
  width: 75%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const NoteTitle = styled.h2``;

const Button = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #218838;
  }
`;

const NewNoteContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Notes: React.FC<NotesProps> = ({ claim, updateClaimNotes }) => {
  const [notes, setNotes] = useState<ClaimNotes[]>([]);
  const [newNote, setNewNote] = useState("");
  const [editingNote, setEditingNote] = useState<ClaimNotes | null>(null);

  const fetchNotes = async () => {
    try {
      const data = await authFetch(
        `/claim-notes?claim_h_uuid=${claim?.claim_h_uuid}`
      );
      setNotes(data);
      updateClaimNotes(data);
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  useEffect(() => {
    if (claim) {
      fetchNotes();
    }
  }, [claim?.claim_h_uuid]);

  const addNote = async () => {
    if (!newNote.trim()) return;

    try {
      const userToken = JSON.parse(localStorage.getItem("userToken") || "{}");
      console.log(userToken);
      await authFetch("/claim-notes", {
        method: "POST",
        body: JSON.stringify({
          claim_h_uuid: claim?.claim_h_uuid,
          user_uuid: userToken.user_uuid,
          content: newNote,
        }),
      });
      setNewNote("");
      fetchNotes();
    } catch (e) {
      console.log("Error adding note:", e);
    }
  };

  const updateNote = async () => {
    if (!editingNote) return;

    try {
      await authFetch("/claim-notes", {
        method: "PUT",
        body: JSON.stringify({
          id: editingNote.id,
          content: editingNote.content,
        }),
      });
      setEditingNote(null);
      fetchNotes();
    } catch (e) {
      console.log("Error updating note:", e);
    }
  };

  const deleteNote = async (id: number) => {
    try {
      await authFetch("/claim-notes", {
        method: "DELETE",
        body: JSON.stringify({ id }),
      });
      fetchNotes();
    } catch (e) {
      console.log("Error deleting note:", e);
    }
  };

  if (!claim) {
    return <NotesContainer>Select a claim to view notes</NotesContainer>;
  }

  return (
    <NotesContainer>
      <NoteTitle>Notes</NoteTitle>
      {notes.map((note) => (
        <NoteItem key={note.id}>
          <NoteHeader>
            <span>{note.user_name}</span>
            <span>
              {new Date(note.updated_at).toLocaleString()}
              {note.updated_at !== note.created_at ? " (edited)" : ""}
            </span>
          </NoteHeader>
          {editingNote && editingNote.id === note.id ? (
            <NewNoteContainer>
              <NoteInput
                value={editingNote.content}
                onChange={(e) =>
                  setEditingNote({ ...editingNote, content: e.target.value })
                }
              />
              <Button onClick={updateNote}>Save</Button>
              <Button onClick={() => setEditingNote(null)}>Cancel</Button>
            </NewNoteContainer>
          ) : (
            <>
              <NoteContent>{note.content}</NoteContent>
              <Button onClick={() => setEditingNote(note)}>Edit</Button>
              <Button onClick={() => deleteNote(note.id)}>Delete</Button>
            </>
          )}
        </NoteItem>
      ))}
      <NewNoteContainer>
        <NoteInput
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Add a new note..."
        />
        <Button onClick={addNote}>Add Note</Button>
      </NewNoteContainer>
    </NotesContainer>
  );
};

export default Notes;
