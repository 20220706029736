// src/hooks/useErrorHandler.ts
import { useState } from "react";
import { ErrorState } from "../Constants/interfaces";

export const useErrorHandler = () => {
  const [error, setError] = useState<ErrorState>({
    hasError: false,
    message: "",
  });

  const handleError = (err: any) => {
    console.error("An error occurred:", err);
    let message = "An unexpected error occurred. Please try again.";

    if (err instanceof Error) {
      message = err.message;
    } else if (typeof err === "string") {
      message = err;
    }

    if (
      message.includes("Unauthorized") ||
      message.includes("Authentication Error")
    ) {
      message =
        "You are not authorized to perform this action. Please log in or contact an administrator.";
    }

    setError({ hasError: true, message });
  };

  const clearError = () => {
    setError({ hasError: false, message: "" });
  };

  return { error, handleError, clearError };
};
