// components/Analysis.tsx
import React from "react";
import styled from "styled-components";
import { AnalysisProps } from "../Constants/interfaces";
import ReactMarkdown from "react-markdown";

const AnalysisContainer = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background: #f9f9f9;
  margin-bottom: 20px;
`;

const Status = styled.div`
  background-color: #d1e7d1;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: left;
  margin-bottom: 10px;
  padding-left: 20px;
  text-align: left;
`;

const StatusIcon = styled.span`
  font-size: 20px;
  color: #228b22;
  margin-right: 10px;
`;

const Confidence = styled.div`
  background-color: #d1e7d1;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin-bottom: 10px;
`;

const ConfidenceBar = styled.div`
  width: 100%;
  height: 15px;
  background-color: #f8f8f8;
  border-radius: 5px;
  overflow: hidden;
`;

const ConfidenceFill = styled.div`
  height: 100%;
  background-color: #28a745;
  border-radius: 5px 0 0 5px;
`;

const Explanation = styled.div`
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const Steps = styled.div`
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 15px;
`;

const Step = styled.p`
  margin-bottom: 8px;
  line-height: 1.5;
`;

const Analysis: React.FC<AnalysisProps> = ({ claim }) => {
  if (!claim) {
    return (
      <AnalysisContainer>Select a claim to view analysis</AnalysisContainer>
    );
  }

  // the claim.highbound_analysis might be a JSON string of an array or
  // something we want to comma separate or display in markdown
  let analysis = claim.highbound_analysis;
  if (typeof analysis === "string") {
    try {
      let analysisArray = JSON.parse(analysis);
      if (Array.isArray(analysisArray)) {
        analysis = analysisArray.join(`\n\n`);
      }
    } catch (error) {
      analysis = claim.highbound_analysis;
    }
  }
  return (
    <AnalysisContainer>
      <h3>Summary</h3>
      <Explanation>
        <p>{claim.claim_status_summary}</p>
      </Explanation>
      <Explanation>
        <h4>835 Analysis</h4>
        <ReactMarkdown>{analysis}</ReactMarkdown>
      </Explanation>
      {/* <Steps>
        <h4>Recommended Corrective Actions</h4>
        <Step>{claim.resubmission_next_steps}</Step>
      </Steps> */}
    </AnalysisContainer>
  );
};

export default Analysis;
