import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { authFetch } from "../../Utils/authFetch";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorDisplay from "../../components/ErrorDisplay";
import { showAlert, showConfirm, showPrompt } from "../../Utils/alerts";

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Arial", sans-serif;
`;

const PageTitle = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 100px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
`;

const Th = styled.th`
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    background-color: #2980b9;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
`;

interface GlobalNote {
  id: number;
  prompt_type: string;
  note_key: string;
  note_value: string;
}

const GlobalNotes: React.FC = () => {
  const [notes, setNotes] = useState<GlobalNote[]>([]);
  const [newNote, setNewNote] = useState<Omit<GlobalNote, "id">>({
    prompt_type: "claimAnalysis",
    note_key: "",
    note_value: "",
  });
  const { error, handleError, clearError } = useErrorHandler();

  const fetchNotes = async () => {
    try {
      const data = await authFetch("/global-notes");
      setNotes(data);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setNewNote((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authFetch("/global-notes", {
        method: "POST",
        body: JSON.stringify(newNote),
      });
      showAlert("Success", "Note added successfully", "success");
      setNewNote({
        prompt_type: "claimAnalysis",
        note_key: "",
        note_value: "",
      });
      fetchNotes();
    } catch (err) {
      handleError(err);
    }
  };

  const handleEdit = async (note: GlobalNote) => {
    try {
      const updatedNote = await showPrompt(
        "Edit Note",
        "Enter the new note value:",
        note.note_value
      );
      if (updatedNote !== null) {
        await authFetch(`/global-notes/${note.id}`, {
          method: "PUT",
          body: JSON.stringify({ ...note, note_value: updatedNote }),
        });
        showAlert("Success", "Note updated successfully", "success");
        fetchNotes();
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleDelete = async (id: number) => {
    const result = await showConfirm(
      "Delete Note",
      "Are you sure you want to delete this note?"
    );
    if (result.isConfirmed) {
      try {
        await authFetch(`/global-notes/${id}`, { method: "DELETE" });
        showAlert("Success", "Note deleted successfully", "success");
        fetchNotes();
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <PageContainer>
      <PageTitle>Global Prompt Notes</PageTitle>
      <ErrorDisplay error={error} onClose={clearError} />

      <Form onSubmit={handleSubmit}>
        <Select
          name="prompt_type"
          value={newNote.prompt_type}
          onChange={handleInputChange}
          required
        >
          <option value="claimAnalysis">Claim Analysis</option>
          <option value="resubmissionAnalysis">Resubmission Analysis</option>
        </Select>
        <Input
          type="text"
          name="note_key"
          value={newNote.note_key}
          onChange={handleInputChange}
          placeholder="Note Key"
          required
        />
        <TextArea
          name="note_value"
          value={newNote.note_value}
          onChange={handleInputChange}
          placeholder="Note Value"
          required
        />
        <Button type="submit">Add Note</Button>
      </Form>

      <Table>
        <thead>
          <tr>
            <Th>Prompt Type</Th>
            <Th>Key</Th>
            <Th>Value</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {notes.map((note) => (
            <tr key={note.id}>
              <Td>{note.prompt_type}</Td>
              <Td>{note.note_key}</Td>
              <Td>{note.note_value}</Td>
              <Td>
                <Button onClick={() => handleEdit(note)}>Edit</Button>
                <DeleteButton onClick={() => handleDelete(note.id)}>
                  Delete
                </DeleteButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PageContainer>
  );
};

export default GlobalNotes;
