// src/components/Header.tsx
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { authFetch } from "../Utils/authFetch";
import { Tenant } from "../Constants/interfaces";

const StyledHeader = styled.header`
  background: #28a745;
  color: #fff;
  padding: 20px;
  position: fixed;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
`;

const Logo = styled.img`
  height: 40px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const LogoutButton = styled.button`
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #fff;
    color: #28a745;
  }
`;

const TenantSelector = styled.select`
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #fff;
  color: #28a745;
  border: none;
  cursor: pointer;
`;

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const fetchTenants = async () => {
    try {
      const data = await authFetch("/tenants/user-tenants");
      setTenants(data);

      const storedTenant = localStorage.getItem("selectedTenant");
      if (
        storedTenant &&
        data.some((tenant: Tenant) => tenant.tenant_uuid === storedTenant)
      ) {
        setSelectedTenant(storedTenant);
      } else if (data.length > 0) {
        setSelectedTenant(data[0].tenant_uuid);
        localStorage.setItem("selectedTenant", data[0].tenant_uuid);
      }
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
  };

  useEffect(() => {
    const checkLoginStatus = () => {
      const accessToken = localStorage.getItem("accessToken");
      setIsLoggedIn(!!accessToken);
    };

    checkLoginStatus();
    if (isLoggedIn) {
      fetchTenants();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // Refresh tenants when the route changes to '/' (after login)
    if (location.pathname === "/" && isLoggedIn) {
      fetchTenants();
    }
  }, [location, isLoggedIn]);

  const handleTenantChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTenantUuid = e.target.value;
    setSelectedTenant(newTenantUuid);
    localStorage.setItem("selectedTenant", newTenantUuid);
    // refresh the page to load the new tenant data
    window.location.reload();
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("selectedTenant");
    setIsLoggedIn(false);
    setSelectedTenant("");
    setTenants([]);
    navigate("/login");
  };

  return (
    <StyledHeader>
      <Link to="/">
        <Logo
          src="https://images.squarespace-cdn.com/content/v1/6668790156d2fc4c1fe49406/194437ba-285a-4faf-aa13-9d96d92d5f16/Asset+2.png?format=1500w"
          alt="Highbound Health Logo"
        />
      </Link>
      <Nav>
        {isLoggedIn && (
          <>
            <TenantSelector
              value={selectedTenant}
              onChange={handleTenantChange}
            >
              <option value="">Select a tenant</option>
              {tenants.map((tenant) => (
                <option key={tenant.tenant_uuid} value={tenant.tenant_uuid}>
                  {tenant.tenant_name}
                </option>
              ))}
            </TenantSelector>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/dashboard">Dashboard</NavLink>
            <NavLink to="/analytics">Analytics</NavLink>
            <NavLink to="/claim-upload">Claim Upload</NavLink>
            <NavLink to="/tenant-settings">Company Settings</NavLink>

            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </>
        )}
        {!isLoggedIn && <NavLink to="/login">Login</NavLink>}
      </Nav>
    </StyledHeader>
  );
};

export default Header;
