// components/ClaimModal.tsx
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import Analysis from "./Analysis";
import ServicePayments from "./ServicePayments";
import Notes from "./Notes";
import { ClaimModalProps, CombinedClaim } from "../Constants/interfaces";
import ClaimDetails from "./ClaimDetails";
import ResubmissionAnalysisComponent from "./ResubmissionAnalysis";
import { authFetch } from "../Utils/authFetch";
import Associated835s from "./Associated835s";
import QuestionAndAnswers from "./QuestionAndAnswers";
import { showAlert } from "../Utils/alerts";

const ModalContent = styled.div`
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  transition: background-color 0.2s ease;
  font-size: 16px;

  &:hover {
    background-color: #218838;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleText = styled.h1`
  margin: 0;
  margin-bottom: 20px;
`;

const RegenerateButton = styled(StyledButton)`
  background-color: #f39c12;
  &:hover {
    background-color: #e67e22;
  }
  margin-bottom: 20px;
`;

const ClaimModal: React.FC<ClaimModalProps> = ({
  claim,
  handleAddTag,
  handleDeleteTag,
  onClose,
  setChatScreenState,
}) => {
  const [resubmissionAnalysis, setResubmissionAnalysis] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchResubmissionAnalysis = async () => {
    setIsLoading(true);
    try {
      const response = await authFetch(
        `/resubmission-analysis?claim_h_uuid=${claim.claim_h_uuid}`
      );
      let analysisData = response.analysis_data;
      // check if string and parse

      if (typeof analysisData === "string") {
        analysisData = JSON.parse(analysisData);
      }

      setResubmissionAnalysis(analysisData);
    } catch (error) {
      console.error("Error fetching resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchResubmissionAnalysis();
  }, [claim.claim_h_uuid]);

  const handleRegenerateAnalysis = async () => {
    setIsLoading(true);
    try {
      // I'm worried this might timeout
      setResubmissionAnalysis(null);
      let result = await authFetch("/resubmission-analysis", {
        method: "POST",
        body: JSON.stringify({ claim_h_uuid: claim.claim_h_uuid }),
      });
      setResubmissionAnalysis(resubmissionAnalysis.analysis_data);
      fetchResubmissionAnalysis();
    } catch (error) {
      console.error("Error regenerating resubmission analysis:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getClaimModalScreenState = () => {
    return {
      screenName: "Claim Details",
      screenDescription: `Viewing details for claim ${claim.claim_name}`,
      screenUrl: window.location.href,
      screenContext: {
        claim,
        resubmissionAnalysis,
      },
      screenActions: [
        {
          type: "action",
          actionName: "addNote",
          parameters: [
            {
              parameterName: "content",
              description: "Content of the note to add",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "deleteNote",
          parameters: [
            {
              parameterName: "id",
              description: "id of the note to delete",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "addTag",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to add to the claim (or new tag)",
              type: "string",
              required: true,
            },
          ],
        },
        {
          type: "action",
          actionName: "removeTag",
          parameters: [
            {
              parameterName: "tag",
              description: "Tag to remove from the claim",
              type: "string",
              required: true,
              options: claim.tags,
            },
          ],
        },
        {
          type: "action",
          actionName: "regenerateAnalysis",
          parameters: [],
        },
        {
          type: "action",
          actionName: "answerQuestion",
          parameters: [
            {
              parameterName: "question",
              description: "Question to answer",
              type: "string",
              required: true,
              options:
                resubmissionAnalysis?.additional_info_needed?.map(
                  (q: { question: any }) => q.question || ""
                ) || [],
            },
            {
              parameterName: "answer",
              description: "Answer to the question",
              type: "string",
              required: true,
            },
          ],
        },
      ],
    };
  };

  useEffect(() => {
    // Update the screen state when the modal is opened
    const screenState = getClaimModalScreenState();
    setChatScreenState(screenState);
  }, [claim, resubmissionAnalysis, claim.notes]);

  if (!claim) {
    return null;
  }

  let open = true;

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalContent>
        <ClaimDetails
          claim={claim}
          onTagAdd={(claimHUuid: string, tag: string) => {
            console.log("Tag added:", claimHUuid, tag);
            handleAddTag(claimHUuid, tag);
          }}
          onTagRemove={(claimHUuid: string, tag: string) => {
            console.log("Tag removed:", claimHUuid, tag);
            handleDeleteTag(claimHUuid, tag);
          }}
        />
        <ServicePayments claim={claim} />
        <Analysis claim={claim} />
        <Notes
          claim={claim}
          updateClaimNotes={(notes) => {
            console.log(notes);
            claim.notes = notes;
          }}
        />
        <ResubmissionAnalysisComponent
          analysis={resubmissionAnalysis}
          isLoading={isLoading}
          handleRegenerateAnalysis={handleRegenerateAnalysis}
        />
        <QuestionAndAnswers
          claim={claim}
          resubmissionAnalysis={resubmissionAnalysis}
        />
        <Actions>
          {!isLoading && (
            <RegenerateButton
              onClick={handleRegenerateAnalysis}
              disabled={isLoading}
            >
              Generate AI Next Steps
            </RegenerateButton>
          )}
        </Actions>
        <Associated835s transactions={claim.transactions || []} />
      </ModalContent>
    </Modal>
  );
};

export default ClaimModal;
