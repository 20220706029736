// src/Utils/authFetch.ts

import { AUTH_ERROR, BASE_API_URL } from "../Constants/constants";
import { refreshToken } from "./tokenRefresh";

export const authFetch = async (
  endpoint: string,
  options: RequestInit = {},
  allowRefresh = true,
  stream = false
): Promise<any> => {
  const accessToken = localStorage.getItem("accessToken");
  const selectedTenant = localStorage.getItem("selectedTenant");

  if (!accessToken) {
    throw new Error("No access token found. User might not be authenticated.");
  }

  const headers: any = {
    ...options.headers,
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  if (selectedTenant) {
    headers["X-Tenant-ID"] = selectedTenant;
  }

  const url = `${BASE_API_URL}${endpoint}`;

  try {
    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (stream) {
      return response;
    }

    if (!response.ok) {
      if (response.status === 401 && allowRefresh) {
        // Try to refresh the token
        const refreshSuccessful = await refreshToken();
        if (refreshSuccessful) {
          // Retry the original request with the new token
          return authFetch(endpoint, options, false);
        } else {
          throw new Error(AUTH_ERROR);
        }
      }
      const error = await response.json();
      throw new Error(error.message || error.error || "Request failed");
    }

    return response.json();
  } catch (error) {
    if ((error as Error).message === AUTH_ERROR) {
      throw error;
    }
    console.error(error);
    throw new Error((error as Error).message || "Request failed");
  }
};
