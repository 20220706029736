import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components"; // Import styled-components
import CheckAuthComponent from "../../Utils/CheckAuthComponent";
import { refreshToken } from "../../Utils/tokenRefresh";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    const accessToken = localStorage.getItem("accessToken");
    if (!userToken || !accessToken) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <HomePageContainer>
      <HeroSection>
        <HeroContent>
          <HeroTitle>Welcome to Highbound Health</HeroTitle>
          <HeroSubtitle>Be Done with Denials</HeroSubtitle>
          <HeroButton onClick={() => navigate("/dashboard")}>
            Get Started
          </HeroButton>
        </HeroContent>
      </HeroSection>
      <CheckAuthComponent />
      <FeaturesSection>
        <Feature>
          <FeatureTitle>Easy Tracking</FeatureTitle>
          <FeatureDescription>
            Track all your health claims in one place with ease and convenience.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureTitle>Analytics</FeatureTitle>
          <FeatureDescription>
            Get insights and analytics on your health claims to make informed
            decisions.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureTitle>Secure</FeatureTitle>
          <FeatureDescription>
            Your data is secure with us, ensuring privacy and protection.
          </FeatureDescription>
        </Feature>
      </FeaturesSection>
    </HomePageContainer>
  );
};

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f2f5;
  padding-top: 80px;
`;

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50vh;
  padding: 40px 20px;
  background: url("https://images.squarespace-cdn.com/content/6668790156d2fc4c1fe49406/1838fa0a-3dac-488a-a359-f2551c2442fa/mtns.jpg?content-type=image%2Fjpeg")
    no-repeat center center;
  background-size: cover;
  color: white;
  text-align: center;
`;

const HeroContent = styled.div`
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  border-radius: 10px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 30px;
`;

const HeroButton = styled.button`
  background-color: #ffffff;
  color: #28a745;
  border: none;
  padding: 10px 20px;
  font-size: 1.25rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e9ecef;
  }
`;

const FeaturesSection = styled.section`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 40px 20px;
  background-color: #ffffff;
`;

const Feature = styled.div`
  flex: 1;
  max-width: 300px;
  text-align: center;
  padding: 20px;
`;

const FeatureTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
`;

export default HomePage;
