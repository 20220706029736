import { authFetch } from "../Utils/authFetch";
import { CombinedClaim } from "../Constants/interfaces";

export const getClaims = async (
  params: Record<string, string>
): Promise<{ claims: CombinedClaim[]; totalCount: number }> => {
  const queryParams = new URLSearchParams();

  // Add sorting parameters
  if (params.sortBy) {
    queryParams.append("sortBy", params.sortBy);
    queryParams.append("sortOrder", params.sortOrder || "ASC");
  }

  // Add pagination parameters
  if (params.page) {
    queryParams.append("page", params.page);
  }
  if (params.pageSize) {
    queryParams.append("pageSize", params.pageSize);
  }

  // Add search parameter
  if (params.search) {
    queryParams.append("search", params.search);
  }

  if (params.tag) {
    queryParams.append("tag", params.tag);
  }

  // Add filtering parameters
  Object.entries(params).forEach(([key, value]) => {
    if (
      ![
        "sortBy",
        "sortOrder",
        "page",
        "pageSize",
        "search",
        "claim_h_uuid",
      ].includes(key) &&
      value
    ) {
      queryParams.append(key, value);
    }
  });

  const url = `/combined-claims?${queryParams.toString()}`;

  try {
    const response = await authFetch(url);
    return response as { claims: CombinedClaim[]; totalCount: number };
  } catch (error) {
    console.error("Error fetching claims:", error);
    throw error;
  }
};

export const getDistinctValues = async (): Promise<
  Record<string, string[]>
> => {
  try {
    const response = await authFetch("/distinct-values");
    return response as Record<string, string[]>;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};
