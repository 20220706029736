import JSZip from "jszip";

export const extractZipContents = async (
  file: File
): Promise<Map<string, string>> => {
  const zip = new JSZip();
  const contents = new Map<string, string>();

  try {
    const zipContents = (await zip.loadAsync(file)) as any;

    let entries = Object.entries(zipContents.files);

    for (let x = 0; x < entries.length; x++) {
      const [filename, zipEntry] = entries[x] as [string, any];
      if (!zipEntry.dir) {
        const content = await zipEntry.async("string");
        contents.set(filename, content);
      }
    }
  } catch (error) {
    console.error("Error extracting ZIP file:", error);
    throw error;
  }

  return contents;
};
