import { Segment, Transaction } from "../../Constants/interfaces";

interface SegmentDefinition {
  segment_name: string;
  segment_description: string;
  parser: (
    elements: string[]
  ) => Record<string, string | number | boolean | any>;
  containsPHI: boolean | ((elements: string[]) => boolean);
  applicableToFileTypes: string[];
  phiProperties?: string[];
}

export class EDIParser {
  public segmentDefinitions: Record<string, SegmentDefinition> = {
    ISA: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Interchange Control Header",
      segment_description:
        "Indicates the start of an interchange of zero or more functional groups and interchange-related control segments",
      containsPHI: false,
      parser: (elements) => ({
        auth_info_qualifier: elements[1],
        auth_info: elements[2],
        security_info_qualifier: elements[3],
        security_info: elements[4],
        interchange_sender_id_qualifier: elements[5],
        interchange_sender_id: elements[6],
        interchange_receiver_id_qualifier: elements[7],
        interchange_receiver_id: elements[8],
        interchange_date: elements[9],
        interchange_time: elements[10],
        repetition_separator: elements[11],
        interchange_control_version_number: elements[12],
        interchange_control_number: elements[13],
        acknowledgment_requested: elements[14],
        usage_indicator: elements[15],
        component_element_separator: elements[16],
      }),
    },
    GS: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Functional Group Header",
      segment_description:
        "Indicates the beginning of a functional group and provides control information",
      containsPHI: false,
      parser: (elements) => ({
        functional_identifier_code: elements[1],
        application_senders_code: elements[2],
        application_receivers_code: elements[3],
        date: elements[4],
        time: elements[5],
        group_control_number: elements[6],
        responsible_agency_code: elements[7],
        version_release_industry_identifier_code: elements[8],
      }),
    },
    ST: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Transaction Set Header",
      segment_description:
        "Indicates the start of a transaction set and assigns a control number",
      containsPHI: false,
      parser: (elements) => ({
        transaction_set_identifier_code: elements[1],
        transaction_set_control_number: elements[2],
      }),
    },
    BPR: {
      applicableToFileTypes: ["835"],
      segment_name: "Financial Information",
      segment_description:
        "Indicates the beginning of a payment order/remittance advice transaction set",
      containsPHI: false,
      parser: (elements) => ({
        transaction_handling_code: elements[1],
        total_actual_provider_payment_amount: elements[2],
        credit_or_debit_flag_code: elements[3],
        payment_method_code: elements[4],
        payment_format_code: elements[5],
        dfi_id_number_qualifier: elements[6],
        dfi_identification_number: elements[7],
        account_number_qualifier: elements[8],
        account_number: elements[9],
        originating_company_identifier: elements[10],
        originating_company_supplemental_code: elements[11],
        dfi_id_number_qualifier_2: elements[12],
        dfi_identification_number_2: elements[13],
        account_number_qualifier_2: elements[14],
        account_number_2: elements[15],
        date: elements[16],
        business_function_code: elements[17],
        dfi_id_number_qualifier_3: elements[18],
        dfi_identification_number_3: elements[19],
        account_number_qualifier_3: elements[20],
        account_number_3: elements[21],
      }),
    },
    TRN: {
      applicableToFileTypes: ["835"],
      segment_name: "Trace",
      segment_description:
        "Uniquely identifies a claim payment and its remittance advice",
      containsPHI: false,
      parser: (elements) => ({
        trace_type_code: elements[1],
        reference_identification: elements[2],
        originating_company_identifier: elements[3],
        reference_identification_2: elements[4],
      }),
    },
    REF: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Reference Information",
      segment_description: "Specifies identifying information",
      containsPHI: false,
      parser: (elements) => ({
        reference_identification_qualifier: elements[1],
        reference_identification: elements[2],
        description: elements[3],
      }),
    },
    DTM: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Date/Time Reference",
      segment_description: "Specifies pertinent dates and times",
      containsPHI: false,
      parser: (elements) => ({
        date_time_qualifier: elements[1],
        date: elements[2],
        time: elements[3],
        time_code: elements[4],
      }),
    },
    N1: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Party Identification",
      segment_description:
        "Identifies a party by type of organization, name, and code",
      containsPHI: (elements) => {
        const entityIdentifierCode = elements[1];
        // Check if the entity is an individual (e.g., patient, subscriber)
        return ["IL", "QC", "PAT", "EMP"].includes(entityIdentifierCode);
      },
      phiProperties: ["name"],
      parser: (elements) => ({
        entity_identifier_code: elements[1],
        name: elements[2],
        identification_code_qualifier: elements[3],
        identification_code: elements[4],
      }),
    },

    N3: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Party Location",
      segment_description: "Specifies the location of the named party",
      containsPHI: true, // Address information is always considered PHI
      phiProperties: ["address_information", "address_information_2"],
      parser: (elements) => ({
        address_information: elements[1],
        address_information_2: elements[2],
      }),
    },

    N4: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Geographic Location",
      segment_description: "Specifies the geographic place of the named party",
      containsPHI: true, // Geographic information is always considered PHI
      phiProperties: [
        "city_name",
        "state_or_province_code",
        "postal_code",
        "country_code",
      ],
      parser: (elements) => ({
        city_name: elements[1],
        state_or_province_code: elements[2],
        postal_code: elements[3],
        country_code: elements[4],
      }),
    },
    OER: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Outbound Encounter Report",
      segment_description: "Provides information about an encounter",
      containsPHI: false,
      parser: (elements) => ({
        encounter_report_type_code: elements[1],
        encounter_report_status_code: elements[2],
        encounter_report_control_number: elements[3],
        encounter_report_date: elements[4],
        encounter_report_time: elements[5],
        encounter_report_transmission_date: elements[6],
        encounter_report_transmission_time: elements[7],
        encounter_report_transmission_method_code: elements[8],
        encounter_report_transmission_identification: elements[9],
      }),
    },

    LX: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Transaction Set Line Number",
      segment_description: "Assigns a number to each transaction set line",
      containsPHI: false,
      parser: (elements) => ({
        assigned_number: elements[1],
      }),
    },
    TS2: {
      segment_name: "Provider Summary Information",
      segment_description:
        "Reports provider-level control totals for remittance advice",
      containsPHI: false,
      applicableToFileTypes: ["835"], // Only applicable to 835, not 837
      parser: (elements) => ({
        total_drg_amount: elements[1],
        total_federal_specific_amount: elements[2],
        total_hospital_specific_amount: elements[3],
        total_disproportionate_share_amount: elements[4],
        total_capital_amount: elements[5],
        total_indirect_medical_education_amount: elements[6],
        total_outlier_day_count: elements[7],
        total_day_outlier_amount: elements[8],
        total_cost_outlier_amount: elements[9],
        average_drg_length_of_stay: elements[10],
        total_discharge_count: elements[11],
        total_cost_report_day_count: elements[12],
        total_covered_day_count: elements[13],
        total_noncovered_day_count: elements[14],
        total_msp_pass_through_amount: elements[15],
        average_drg_weight: elements[16],
        total_pps_capital_fsp_drg_amount: elements[17],
        total_pps_capital_hsp_drg_amount: elements[18],
        total_pps_dsh_drg_amount: elements[19],
      }),
    },
    TS3: {
      segment_name: "Provider Summary Information",
      segment_description: "Reports provider-level control totals",
      containsPHI: false,
      applicableToFileTypes: ["837", "835"],
      parser: (elements) => ({
        provider_identifier: elements[1],
        facility_type_code: elements[2],
        fiscal_period_date: elements[3],
        total_claim_count: elements[4],
        total_claim_charge_amount: elements[5],
        monetary_amount: elements[6],
        total_mspdp_payer_amount: elements[7],
        total_non_lab_charge_amount: elements[8],
        total_hcpcs_reported_charge_amount: elements[9],
        total_hcpcs_payable_amount: elements[10],
        total_professional_component_amount: elements[11],
        total_msp_patient_liability_met_amount: elements[12],
        total_patient_reimbursement_amount: elements[13],
        total_pip_claim_count: elements[14],
        total_pip_amount: elements[15],
      }),
    },
    CLP: {
      applicableToFileTypes: ["835"],
      segment_name: "Claim Payment Information",
      segment_description: "Contains claim-specific data",
      phiProperties: ["claim_submitter_s_identifier"],
      containsPHI: (elements) => {
        return (elements?.[1] && elements?.[1].length > 0) || false;
      },
      parser: (elements) => ({
        claim_submitter_s_identifier: elements[1],
        claim_status_code: elements[2],
        total_claim_charge_amount: elements[3],
        claim_payment_amount: elements[4],
        patient_responsibility_amount: elements[5],
        claim_filing_indicator_code: elements[6],
        payer_claim_control_number: elements[7],
        facility_type_code: elements[8],
        claim_frequency_type_code: elements[9],
        patient_status_code: elements[10],
        diagnosis_related_group_drg_code: elements[11],
        drg_weight: elements[12],
        discharge_fraction: elements[13],
      }),
    },
    NM1: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Individual or Organizational Name",
      segment_description: "Identifies a person or organization",
      containsPHI: (elements) => {
        const entityIdentifierCode = elements[1];
        const entityTypeQualifier = elements[2];
        // Check if the entity is an individual (1) and not a non-person entity (2)
        return (
          entityTypeQualifier === "1" &&
          ["IL", "QC", "PAT", "EMP", "PR", "DN"].includes(entityIdentifierCode)
        );
      },
      phiProperties: [
        "name_first",
        "name_last_or_organization_name",
        "name_middle",
        "name_prefix",
        "name_suffix",
      ],
      parser: (elements) => ({
        entity_identifier_code: elements[1],
        entity_type_qualifier: elements[2],
        name_last_or_organization_name: elements[3],
        name_first: elements[4],
        name_middle: elements[5],
        name_prefix: elements[6],
        name_suffix: elements[7],
        identification_code_qualifier: elements[8],
        identification_code: elements[9],
      }),
    },

    AMT: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Monetary Amount Information",
      segment_description: "Indicates the total monetary amount",
      containsPHI: false,
      parser: (elements) => ({
        amount_qualifier_code: elements[1],
        monetary_amount: elements[2],
        credit_debit_flag_code: elements[3],
      }),
    },
    SVC: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Service Payment Information",
      segment_description: "Specifies the service line item detail for a claim",
      containsPHI: false,
      parser: (elements) => ({
        composite_medical_procedure_identifier: {
          product_or_service_id_qualifier: elements[1]?.split(":")[0] || "",
          product_or_service_id: elements[1]?.split(":")[1] || "",
          procedure_modifier_1: elements[1]?.split(":")[2] || "",
          procedure_modifier_2: elements[1]?.split(":")[3] || "",
          procedure_modifier_3: elements[1]?.split(":")[4] || "",
          procedure_modifier_4: elements[1]?.split(":")[5] || "",
          description: elements[1]?.split(":")[6] || "",
          product_or_service_id_2: elements[1]?.split(":")[7] || "",
        },
        line_item_charge_amount: elements[2],
        line_item_provider_payment_amount: elements[3],
        national_uniform_billing_committee_revenue_code: elements[4],
        units_of_service_paid_count: elements[5],
        service_date: elements[6],
        service_identification: elements[7],
        original_units_of_service_count: elements[8],
        original_line_item_charge_amount: elements[9],
        original_line_item_provider_payment_amount: elements[10],
        paid_service_unit_count: elements[11],
        bundled_or_unbundled_line_number: elements[12],
        service_tax_amount: elements[13],
        facility_tax_amount: elements[14],
        prescription_number: elements[15],
        drug_information: {
          quantity: elements[16]?.split(":")[0] || "",
          unit_or_basis_for_measurement_code: elements[16]?.split(":")[1] || "",
          sample_selection_modulus: elements[16]?.split(":")[2] || "",
        },
        postage_claimed_amount: elements[17],
        monetary_amount: elements[18],
        emergency_related_indicator: elements[19],
        prior_authorization_number: elements[20],
        contract_information: {
          contract_type_code: elements[21]?.split(":")[0] || "",
          contract_amount: elements[21]?.split(":")[1] || "",
          contract_percentage: elements[21]?.split(":")[2] || "",
          contract_code: elements[21]?.split(":")[3] || "",
          terms_discount_percentage: elements[21]?.split(":")[4] || "",
          contract_version_identifier: elements[21]?.split(":")[5] || "",
        },
      }),
    },
    CAS: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Claim Adjustment",
      segment_description: "Contains claim adjustment reason codes and amounts",
      containsPHI: false,
      parser: (elements) => {
        const adjustments = [];
        for (let i = 2; i < elements.length; i += 3) {
          if (elements[i] && elements[i + 1]) {
            adjustments.push({
              claim_adjustment_reason_code: elements[i],
              adjustment_amount: elements[i + 1],
              adjustment_quantity: elements[i + 2] || null,
            });
          }
        }
        return {
          claim_adjustment_group_code: elements[1],
          adjustments,
        };
      },
    },
    SE: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Transaction Set Trailer",
      segment_description:
        "Indicates the end of the transaction set and provides the count of transmitted segments",
      containsPHI: false,
      parser: (elements) => ({
        number_of_included_segments: elements[1],
        transaction_set_control_number: elements[2],
      }),
    },
    GE: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Functional Group Trailer",
      segment_description:
        "Indicates the end of a functional group and provides control information",
      containsPHI: false,
      parser: (elements) => ({
        number_of_transaction_sets_included: elements[1],
        group_control_number: elements[2],
      }),
    },
    IEA: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Interchange Control Trailer",
      segment_description:
        "Defines the end of an interchange of zero or more functional groups and interchange-related control segments",
      containsPHI: false,
      parser: (elements) => ({
        number_of_included_functional_groups: elements[1],
        interchange_control_number: elements[2],
      }),
    },
    PER: {
      applicableToFileTypes: ["835", "837"],
      segment_name: "Administrative Communications Contact",
      segment_description:
        "Identifies a person or office to whom administrative communications should be directed",
      containsPHI: (elements) => {
        // Check if the contact is an individual (contains a name)
        return (elements[2] && elements[2].length > 0) || false;
      },
      phiProperties: ["name"],
      parser: (elements) => ({
        contact_function_code: elements[1],
        name: elements[2],
        communication_number_qualifier_1: elements[3],
        communication_number_1: elements[4],
        communication_number_qualifier_2: elements[5],
        communication_number_2: elements[6],
        communication_number_qualifier_3: elements[7],
        communication_number_3: elements[8],
      }),
    },
    MOA: {
      applicableToFileTypes: ["835"],
      segment_name: "Outpatient Adjudication Information",
      segment_description:
        "Provides summary-level information about claim adjustments and payments",
      containsPHI: false,
      parser: (elements) => ({
        reimbursement_rate: elements[1],
        hcpcs_payable_amount: elements[2],
        claim_payment_remark_code_1: elements[3],
        claim_payment_remark_code_2: elements[4],
        claim_payment_remark_code_3: elements[5],
        claim_payment_remark_code_4: elements[6],
        claim_payment_remark_code_5: elements[7],
        end_stage_renal_disease_payment_amount: elements[8],
        non_payable_professional_component_amount: elements[9],
      }),
    },
    PLB: {
      segment_name: "Provider Level Adjustment",
      segment_description: "Identifies provider level adjustments",
      containsPHI: false,
      applicableToFileTypes: ["835"],
      parser: (elements) => {
        const adjustments = [];
        for (let i = 3; i < elements.length; i += 2) {
          if (elements[i] && elements[i + 1]) {
            adjustments.push({
              adjustment_reason_code: elements[i],
              provider_adjustment_amount: elements[i + 1],
            });
          }
        }
        return {
          provider_identifier: elements[1],
          fiscal_period_date: elements[2],
          adjustments,
        };
      },
    },
    // Add new 837-specific segments
    BHT: {
      segment_name: "Beginning of Hierarchical Transaction",
      segment_description:
        "To define the business hierarchical structure of the transaction set and identify the business application purpose and reference data",
      containsPHI: false,
      applicableToFileTypes: ["837"], // BHT is typically used in 837 transactions
      parser: (elements) => ({
        hierarchical_structure_code: elements[1],
        transaction_set_purpose_code: elements[2],
        originator_application_transaction_identifier: elements[3],
        transaction_set_creation_date: elements[4],
        transaction_set_creation_time: elements[5],
        transaction_type_code: elements[6],
      }),
    },
    CLM: {
      segment_name: "Claim Information",
      segment_description: "Contains information about the claim",
      containsPHI: true,
      applicableToFileTypes: ["837"],
      phiProperties: ["patient_control_number"],
      parser: (elements) => ({
        patient_control_number: elements[1],
        total_claim_charge_amount: elements[2],
        claim_filing_indicator_code: elements[3],
        facility_code_value: elements[4],
        facility_code_qualifier: elements[5],
        frequency_type_code: elements[6],
        signature_indicator: elements[7],
        assignment_or_plan_participation_code: elements[8],
        benefits_assignment_certification_indicator: elements[9],
        release_of_information_code: elements[10],
      }),
    },
    HI: {
      segment_name: "Health Care Diagnosis Code",
      segment_description: "Provides diagnosis information",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        diagnosis_type_code: elements[1],
        diagnosis_code: elements[2],
        diagnosis_date: elements[3],
      }),
    },
    SBR: {
      segment_name: "Subscriber Information",
      segment_description: "Provides information about the subscriber",
      containsPHI: true,
      applicableToFileTypes: ["837"],
      phiProperties: ["insured_group_or_policy_number", "insured_group_name"],
      parser: (elements) => ({
        payer_responsibility_sequence_number_code: elements[1],
        individual_relationship_code: elements[2],
        insured_group_or_policy_number: elements[3],
        insured_group_name: elements[4],
        insurance_type_code: elements[5],
        claim_filing_indicator_code: elements[6],
      }),
    },

    PAT: {
      segment_name: "Patient Information",
      segment_description: "Provides patient demographic information",
      containsPHI: true,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        patient_relationship_code: elements[1],
        patient_location_code: elements[2],
        employment_status_code: elements[3],
        student_status_code: elements[4],
        date_time_period_format_qualifier: elements[5],
        date_time_period: elements[6],
        unit_or_basis_for_measurement_code: elements[7],
        weight: elements[8],
        yes_no_condition_or_response_code: elements[9],
      }),
    },
    CR1: {
      segment_name: "Ambulance Transport Information",
      segment_description: "Provides information about ambulance transport",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        unit_or_basis_for_measurement_code: elements[1],
        weight: elements[2],
        ambulance_transport_code: elements[3],
        ambulance_transport_reason_code: elements[4],
        unit_or_basis_for_measurement_code_2: elements[5],
        quantity: elements[6],
        address_information: elements[7],
        address_information_2: elements[8],
        description: elements[9],
        description_2: elements[10],
      }),
    },
    CR2: {
      segment_name: "Spinal Manipulation Service Information",
      segment_description:
        "Provides information about spinal manipulation services",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        count: elements[1],
        quantity: elements[2],
        subluxation_level_code: elements[3],
        subluxation_level_code_2: elements[4],
        unit_or_basis_for_measurement_code: elements[5],
        quantity_2: elements[6],
        quantity_3: elements[7],
        patient_condition_code: elements[8],
        yes_no_condition_or_response_code: elements[9],
        yes_no_condition_or_response_code_2: elements[10],
        yes_no_condition_or_response_code_3: elements[11],
        yes_no_condition_or_response_code_4: elements[12],
      }),
    },
    PWK: {
      segment_name: "Paperwork",
      segment_description: "Identifies the type and transmission of paperwork",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        report_type_code: elements[1],
        report_transmission_code: elements[2],
        report_copies_needed: elements[3],
        entity_identifier_code: elements[4],
        identification_code_qualifier: elements[5],
        identification_code: elements[6],
        description: elements[7],
        actions_indicated: elements[8],
        request_category_code: elements[9],
      }),
    },
    K3: {
      segment_name: "File Information",
      segment_description: "Provides supplemental information",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        fixed_format_information: elements[1],
        record_format_code: elements[2],
        composite_unit_of_measure: elements[3],
      }),
    },
    NTE: {
      segment_name: "Note/Special Instruction",
      segment_description: "Provides a free-form description",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        note_reference_code: elements[1],
        description: elements[2],
      }),
    },
    SV1: {
      segment_name: "Professional Service",
      segment_description:
        "Specifies the service line detail for a health care professional",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        composite_medical_procedure_identifier: elements[1],
        monetary_amount: elements[2],
        unit_or_basis_for_measurement_code: elements[3],
        quantity: elements[4],
        facility_code_value: elements[5],
        service_type_code: elements[6],
        composite_diagnosis_code_pointer: elements[7],
        monetary_amount_2: elements[8],
        emergency_indicator: elements[9],
        multiple_procedures_indicator: elements[10],
        epsdt_indicator: elements[11],
        family_planning_indicator: elements[12],
        demonstration_project_indicator: elements[13],
        copay_status_code: elements[14],
        healthcare_professional_shortage_area_code: elements[15],
        diagnostic_related_group_code: elements[16],
        measurement_unit: elements[17],
        unit_rate: elements[18],
        conditions_indicator: elements[19],
        anesthesia_related_procedure: elements[20],
        epsdt_indicator_2: elements[21],
      }),
    },
    SVD: {
      segment_name: "Service Line Adjudication",
      segment_description:
        "Provides line item settlement information for a specific service",
      containsPHI: false,
      applicableToFileTypes: ["835", "837"],
      parser: (elements) => {
        const adjustments = [];
        for (let i = 5; i < elements.length; i += 2) {
          if (elements[i] && elements[i + 1]) {
            adjustments.push({
              adjustment_reason_code: elements[i],
              adjustment_amount: elements[i + 1],
            });
          }
        }
        return {
          other_payer_primary_identifier: elements[1],
          service_line_paid_amount: elements[2],
          composite_medical_procedure_identifier: elements[3],
          product_service_id: elements[4],
          adjustments,
        };
      },
    },
    LIN: {
      segment_name: "Item Identification",
      segment_description:
        "Specifies identifying information for a product or service",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => {
        const identifiers: any = {};
        for (let i = 2; i < elements.length; i += 2) {
          if (elements[i] && elements[i + 1]) {
            identifiers[elements[i]] = elements[i + 1];
          }
        }
        return {
          assigned_identification: elements[1],
          identifiers,
        };
      },
    },
    PRV: {
      segment_name: "Provider Information",
      segment_description:
        "Specifies the identifying characteristics of a provider",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        provider_code: elements[1],
        reference_identification_qualifier: elements[2],
        reference_identification: elements[3],
      }),
    },

    DTP: {
      segment_name: "Date or Time or Period",
      segment_description: "Specifies date, time, or period",
      containsPHI: false,
      applicableToFileTypes: ["835", "837"],
      parser: (elements) => ({
        date_time_qualifier: elements[1],
        date_time_period_format_qualifier: elements[2],
        date_time_period: elements[3],
      }),
    },

    HL: {
      segment_name: "Hierarchical Level",
      segment_description:
        "Identifies dependencies among and the content of hierarchically related groups of data segments",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        hierarchical_id_number: elements[1],
        hierarchical_parent_id_number: elements[2],
        hierarchical_level_code: elements[3],
        hierarchical_child_code: elements[4],
      }),
    },

    DMG: {
      segment_name: "Demographic Information",
      segment_description: "Provides demographic information",
      containsPHI: true,
      applicableToFileTypes: ["837"],
      phiProperties: ["date_of_birth", "gender_code"],
      parser: (elements) => ({
        date_time_period_format_qualifier: elements[1],
        date_of_birth: elements[2],
        gender_code: elements[3],
      }),
    },

    OI: {
      segment_name: "Other Insurance Coverage Information",
      segment_description:
        "Provides information about other insurance coverage",
      containsPHI: false,
      applicableToFileTypes: ["837"],
      parser: (elements) => ({
        claim_filing_indicator_code: elements[1],
        claim_submission_reason_code: elements[2],
        benefits_assignment_certification_indicator: elements[3],
        patient_signature_source_code: elements[4],
        provider_agreement_code: elements[5],
        release_of_information_code: elements[6],
      }),
    },
  };

  private async PHIKeyForProperty(propertyVal: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(propertyVal || "");
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return `${hashHex.substr(0, 8)}-${hashHex.substr(8, 4)}-${hashHex.substr(
      12,
      4
    )}-${hashHex.substr(16, 4)}-${hashHex.substr(20, 12)}`;
  }

  private randomNumber() {
    return Math.floor(Math.random() * 1000000);
  }

  private async addPHIKeysToSegment(segment: Segment) {
    if (!segment.containsPHI) {
      return segment;
    }
    let segmentDefinition = new EDIParser().segmentDefinitions[
      segment.segment_id
    ];
    if (!segmentDefinition) {
      return segment;
    }
    let propertiesToScrub = segmentDefinition.phiProperties || [];
    // srub everything if no properties are specified

    let data: any = {};
    let keys = Object.keys(segment.data);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (propertiesToScrub.includes(key) || propertiesToScrub.length === 0) {
        data[`${key}_phi_key`] = await this.PHIKeyForProperty(
          `${JSON.stringify(segment.data[key] || "")}${this.randomNumber()}`
        );
        data[key] = segment.data[key];
      } else {
        data[key] = segment.data[key];
      }
    }
    return { ...segment, data };
  }

  public async parse(ediContent: string): Promise<Transaction[]> {
    const transactions: Transaction[] = [];
    const parsingInfo = this.getParsingInfo(ediContent);

    // this is everything before the first ST segment
    const globalLevelTransaction = this.preParseTransaction(
      ediContent,
      parsingInfo
    );
    const allTransactionSegments = this.parseSegments(
      globalLevelTransaction,
      ediContent,
      parsingInfo
    );
    const postLastSESegments: Segment[] = [];
    // so now we want to do some splitting and organizing these segments into claims
    let preSTSegments: Segment[] = [];
    let firstSTIndex = 0;
    for (let i = 0; i < allTransactionSegments.length; i++) {
      const segment = allTransactionSegments[i];
      const segmentId = segment.segment_id;
      if (segmentId === "ST") {
        firstSTIndex = i;
        break;
      }
      preSTSegments.push(segment);
    }
    let STSegments: Segment[][] = []; // it's an array of arrays
    let currentSTSegment: Segment[] = [];
    for (let i = firstSTIndex; i < allTransactionSegments.length; i++) {
      const segment = allTransactionSegments[i];
      const segmentId = segment.segment_id;
      if (segmentId === "ST") {
        if (currentSTSegment.length > 0) {
          STSegments.push([...currentSTSegment]);
        }
        currentSTSegment = [segment];
      } else if (segmentId === "SE") {
        currentSTSegment.push(segment);
        STSegments.push([...currentSTSegment]);
        currentSTSegment = [];
      } else if (currentSTSegment.length > 0) {
        currentSTSegment.push(segment);
      } else {
        postLastSESegments.push(segment);
        // this means we're after the SE but havent started a new ST
        // so this is like the GS segment at the end
      }
    }

    // console.log("STSegments", STSegments);
    // console.log("postLastSESegments", postLastSESegments);

    // ok so now we have all the array of ST to SE Segments
    // we need to parse them and create claim objects

    for (let i = 0; i < STSegments.length; i++) {
      const segmentsSTtoSE: Segment[] = STSegments[i];
      let currentClaimSegments: Segment[] = [];
      const segmentsBeforeFirstClaim: Segment[] = [];
      let STSegment: Segment = segmentsSTtoSE[0];
      let SESegment: Segment = segmentsSTtoSE[segmentsSTtoSE.length - 1];
      // we don't want to include the ST and SE segments in the claim
      for (let ii = 1; ii < segmentsSTtoSE.length - 1; ii++) {
        const segment = segmentsSTtoSE[ii];
        const segmentId = segment.segment_id;
        if (segmentId === "CLM" && globalLevelTransaction.file_type === "837") {
          if (currentClaimSegments.length > 0) {
            // at this point we can create the full transaction object we want to save
            let newTransaction = JSON.parse(
              JSON.stringify(globalLevelTransaction)
            );
            newTransaction.segments = [
              ...preSTSegments,
              STSegment,
              ...segmentsBeforeFirstClaim,
              ...currentClaimSegments,
              SESegment,
              ...postLastSESegments,
            ];
            newTransaction.claim_h_uuid = await this.generateUUID(
              currentClaimSegments
            );
            transactions.push(newTransaction);
          }
          // reset currentIdentiferSegment and claim segment for next one
          currentClaimSegments = [segment];
        } else if (
          segmentId === "CLP" &&
          globalLevelTransaction.file_type === "835"
        ) {
          if (currentClaimSegments.length > 0) {
            let newTransaction = JSON.parse(
              JSON.stringify(globalLevelTransaction)
            );
            newTransaction.segments = [
              ...preSTSegments,
              STSegment,
              ...segmentsBeforeFirstClaim,
              ...currentClaimSegments,
              SESegment,
              ...postLastSESegments,
            ];
            newTransaction.claim_h_uuid = await this.generateUUID(
              currentClaimSegments
            );
            transactions.push(newTransaction);
          }
          currentClaimSegments = [segment];
        } else if (currentClaimSegments.length > 0) {
          currentClaimSegments.push(segment);
        } else {
          segmentsBeforeFirstClaim.push(segment);
        }
      }
      // add the last one
      if (currentClaimSegments.length > 0) {
        let newTransaction = JSON.parse(JSON.stringify(globalLevelTransaction));
        newTransaction.segments = [
          ...preSTSegments,
          STSegment,
          ...segmentsBeforeFirstClaim,
          ...currentClaimSegments,
          SESegment,
          ...postLastSESegments,
        ];
        newTransaction.claim_h_uuid = await this.generateUUID(
          currentClaimSegments
        );
        transactions.push(newTransaction);
      }
    }

    // add PHI Keys to segments
    for (let i = 0; i < transactions.length; i++) {
      const transaction = transactions[i];
      let segments: Segment[] = [];
      for (let s = 0; s < transaction.segments.length; s++) {
        let seg = await this.addPHIKeysToSegment(transaction.segments[s]);
        segments.push(seg);
      }
      transaction.segments = segments;
    }
    return transactions;
  }

  public getParsingInfo(ediContent: string): {
    segmentParser: string;
    segmentDelimiter: string;
  } {
    const segmentParser = "~"; // TOOD
    const segmentDelimiter = ediContent.startsWith("ISA|") ? "|" : "*";
    return { segmentParser, segmentDelimiter };
  }

  public preParseTransaction(
    ediContent: string,
    parsingInfo: { segmentParser: string; segmentDelimiter: string }
  ): Transaction {
    const segments = ediContent.split(parsingInfo.segmentParser);
    const transaction: Transaction = {
      transaction_type: "",
      transaction_date: "",
      sender_id: "",
      receiver_id: "",
      claim_h_uuid: "",
      segments: [],
      file_type: "835", // Default to 835, will be updated if it's an 837
    };

    // first we need to determine the file type before parsing other stuff
    segments.forEach((segment) => {
      const elements = segment.split(parsingInfo.segmentDelimiter);
      const segmentId = elements[0];
      if (segmentId === "ISA") {
        transaction.sender_id = (elements?.[6] || "").trim();
        transaction.receiver_id = (elements?.[8] || "").trim();
        transaction.transaction_date = (elements?.[9] || "").trim();
      } else if (segmentId === "GS") {
        transaction.transaction_type = elements?.[1] || "";
        if (elements?.[1] === "HC") {
          transaction.file_type = "837";
        }
      }
    });
    return transaction;
  }

  //    let newTransaction = JSON.parse(JSON.stringify(transaction));

  public parseSegments(
    transaction: Transaction, // for getting the file type
    ediContent: string,
    parsingInfo: { segmentParser: string; segmentDelimiter: string }
  ): Segment[] {
    const segments = ediContent.split(parsingInfo.segmentParser);
    const transactionSegments: Segment[] = [];
    segments.forEach((segment) => {
      const elements = segment.split(parsingInfo.segmentDelimiter);
      const segmentId = elements[0];
      const segmentDefinition = this.segmentDefinitions[segmentId];
      if (
        segmentDefinition &&
        segmentDefinition.applicableToFileTypes.includes(
          transaction.file_type || "unknown"
        )
      ) {
        const parsedSegment: Segment = {
          segment_id: segmentId,
          segment_name: segmentDefinition.segment_name,
          data: segmentDefinition.parser(elements),
          raw: segment,
          containsPHI:
            typeof segmentDefinition.containsPHI === "function"
              ? segmentDefinition.containsPHI(elements)
              : segmentDefinition.containsPHI,
        };
        transactionSegments.push(parsedSegment);
      } else if (!EDIParser.missingSegments.includes(segmentId)) {
        EDIParser.missingSegments.push(segmentId);
        console.log(
          `Missing Segments - ${EDIParser.missingSegments.join(",")}`
        );
        console.log(`Missing Segment - ${segmentId}`, segment);
      }
    });
    return transactionSegments;
  }

  /*
  generate the claim_h_id
   let patientControlNumber = "";
    let claimSubmittersIdentifier = "";
    if (segmentId === "CLM" && newTransaction.file_type === "837") {
        patientControlNumber = elements[1] || "";
      } else if (segmentId === "CLP" && newTransaction.file_type === "835") {
        claimSubmittersIdentifier = elements[1] || "";
      }
    let claim_h_uuid = await this.generateUUID(
      newTransaction.file_type,
      patientControlNumber,
      claimSubmittersIdentifier
    );

    if (!claim_h_uuid) {
      console.log(
        `No data to hash for UUID - is Claim missing a control number? ${JSON.stringify(
          newTransaction
        )}`
      );
      return null;
    } else {
      newTransaction.claim_h_uuid = claim_h_uuid;
    }
      */

  private static missingSegments: string[] = [];

  private async generateUUID(
    currentClaimSegments?: Segment[]
  ): Promise<string | null> {
    let dataToHash = "";

    const currentIdentifierSegment = currentClaimSegments?.find(
      (segment) => segment.segment_id === "CLM" || segment.segment_id === "CLP"
    );
    if (currentIdentifierSegment?.data?.patient_control_number) {
      dataToHash = currentIdentifierSegment.data.patient_control_number + "";
    }
    if (currentIdentifierSegment?.data?.claim_submitter_s_identifier) {
      dataToHash =
        currentIdentifierSegment.data.claim_submitter_s_identifier + "";
    }

    if (!dataToHash) {
      return null;
    }

    // need to involve the tenant to avoid duplicates
    let tenantid = localStorage.getItem("selectedTenant");
    dataToHash = `${dataToHash}${tenantid}`;

    const encoder = new TextEncoder();
    const data = encoder.encode(dataToHash);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return `${hashHex.substr(0, 8)}-${hashHex.substr(8, 4)}-${hashHex.substr(
      12,
      4
    )}-${hashHex.substr(16, 4)}-${hashHex.substr(20, 12)}`;
  }
}
