// src/components/ErrorDisplay.tsx
import React from "react";
import styled from "styled-components";
import { ErrorDisplayProps, ErrorState } from "../Constants/interfaces";

const ErrorContainer = styled.div`
  background-color: #ffebee;
  color: #c62828;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ef9a9a;
`;

const CloseButton = styled.button`
  float: right;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({ error, onClose }) => {
  if (!error.hasError) return null;

  return (
    <ErrorContainer>
      {error.message}
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </ErrorContainer>
  );
};

export default ErrorDisplay;
