// components/ResubmissionAnalysis.tsx
import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const AnalysisContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #f9f9f9;
`;

const SectionTitle = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
`;
const SectionTitleText = styled.h1`
  margin-top: 0;
  margin-bottom: 10px;
  width: 50%;
`;

const QuestionList = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const QuestionItem = styled.li`
  margin-bottom: 10px;
`;

const NextStepsList = styled.ol`
  padding-left: 20px;
`;

const NextStepItem = styled.li`
  margin-bottom: 10px;
`;

const LetterContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #fff;
`;
const StyledButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  transition: background-color 0.2s ease;
  font-size: 16px;
  width: 50%;
  &:hover {
    background-color: #218838;
  }
`;

const RegenerateButton = styled(StyledButton)`
  background-color: #f39c12;
  &:hover {
    background-color: #e67e22;
  }
  margin-bottom: 20px;
`;

const CitationsContainer = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
`;

const CitationItem = styled.div`
  margin-bottom: 15px;
`;

const CitationSource = styled.h4`
  margin-bottom: 5px;
`;

const CitationDetails = styled.p`
  margin: 0;
  font-size: 0.9em;
  color: #666;
`;

interface ResubmissionAnalysisProps {
  analysis: any;
  isLoading: boolean;
  handleRegenerateAnalysis: () => void;
}

const ResubmissionAnalysis: React.FC<ResubmissionAnalysisProps> = ({
  analysis,
  isLoading,
  handleRegenerateAnalysis,
}) => {
  if (isLoading) {
    return (
      <AnalysisContainer>Loading resubmission analysis...</AnalysisContainer>
    );
  }

  if (!analysis) {
    return (
      <AnalysisContainer>No resubmission analysis available.</AnalysisContainer>
    );
  }

  return (
    <AnalysisContainer>
      <SectionTitle>
        <SectionTitleText>Resubmission Analysis</SectionTitleText>
        <RegenerateButton
          onClick={handleRegenerateAnalysis}
          disabled={isLoading}
        >
          {isLoading ? "Generating..." : "Regenerate AI Next Steps"}
        </RegenerateButton>
      </SectionTitle>

      <h4>Approval Analysis:</h4>
      <ReactMarkdown>{analysis.approval_analysis}</ReactMarkdown>

      <h4>Suggested Next Steps:</h4>
      <NextStepsList>
        {(analysis?.next_steps || []).map((step: any, index: number) => (
          <NextStepItem key={index}>
            <strong>{step.step}</strong>: {step.description}
          </NextStepItem>
        ))}
      </NextStepsList>

      <LetterContainer>
        <ReactMarkdown>{analysis?.resubmission_letter || ""}</ReactMarkdown>
      </LetterContainer>

      <h4>Form 837:</h4>
      <pre>{JSON.stringify(analysis.form_837_suggestion, null, 2)}</pre>

      {(analysis?.citations || []).length > 0 ? (
        <CitationsContainer>
          <h3>Sources:</h3>
          {(analysis?.citations || []).map((citation: any, index: number) => (
            <CitationItem key={index}>
              <CitationSource>{citation.source}</CitationSource>
              <CitationDetails>
                {citation.fileName && `File: ${citation.fileName}`}
                {citation.pageNumber && ` | Page: ${citation.pageNumber}`}
              </CitationDetails>
              <CitationDetails>Relevance: {citation.relevance}</CitationDetails>
            </CitationItem>
          ))}
        </CitationsContainer>
      ) : null}
      {/* <h4>Additional Information Requested:</h4>
      <QuestionList>
        {(analysis?.additional_info_needed || []).map(
          (item: any, index: number) => (
            <QuestionItem key={index}>{item.question}</QuestionItem>
          )
        )}
      </QuestionList>
      <p>
        *Please answer any questions below and resubmit the claim for further
        analysis.
      </p> */}
    </AnalysisContainer>
  );
};

export default ResubmissionAnalysis;
