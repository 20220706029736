// src/Pages/TenantManagement/TenantManagement.tsx

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { authFetch } from "../../Utils/authFetch";
import { Tenant, User } from "../../Constants/interfaces";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorDisplay from "../../components/ErrorDisplay";
import { showConfirm, showAlert } from "../../Utils/alerts";

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Arial", sans-serif;
`;

const PageTitle = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 100px;
`;

const Section = styled.section`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #34495e;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 1rem;
  text-align: left;
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #ecf0f1;
`;

const Button = styled.button`
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 0.5rem;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
`;
const ClearDataButton = styled(Button)`
  background-color: #e74c3c;
  &:hover {
    background-color: #c0392b;
  }
`;

const TenantManagement: React.FC = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
  const [formData, setFormData] = useState({ tenant_name: "", logo_url: "" });
  const [newUserEmail, setNewUserEmail] = useState("");
  const { error, handleError, clearError } = useErrorHandler();

  const fetchTenants = async () => {
    try {
      const data = await authFetch("/tenants");
      setTenants(data);
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenantUsers = async (tenantUuid: string) => {
    try {
      const data = await authFetch(`/tenants/users?tenantUuid=${tenantUuid}`);
      setUsers(data);
    } catch (e) {
      handleError(e);
    }
  };

  const selectTenant = (selectedUuid: string) => {
    const tenant = tenants.find((t) => t.tenant_uuid === selectedUuid) || null;
    setSelectedTenant(tenant);
    if (tenant) {
      fetchTenantUsers(tenant.tenant_uuid);
    } else {
      setUsers([]);
    }
  };

  const handleCreateTenant = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authFetch("/tenants", {
        method: "POST",
        body: JSON.stringify(formData),
      });
      fetchTenants();
      setFormData({ tenant_name: "", logo_url: "" });
    } catch (er) {
      handleError(er);
    }
  };

  const handleUpdateTenant = async () => {
    if (!selectedTenant) return;
    try {
      await authFetch("/tenants", {
        method: "PUT",
        body: JSON.stringify({ ...selectedTenant, ...formData }),
      });
      fetchTenants();
      setSelectedTenant(null);
      setFormData({ tenant_name: "", logo_url: "" });
    } catch (e) {
      handleError(e);
    }
  };

  const handleDeleteTenant = async (tenantUuid: string) => {
    try {
      await authFetch(`/tenants?tenantUuid=${tenantUuid}`, {
        method: "DELETE",
      });
      fetchTenants();
      if (selectedTenant?.tenant_uuid === tenantUuid) {
        setSelectedTenant(null);
        setUsers([]);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const handleClearTenantData = async (tenantUuid: string) => {
    const result = await showConfirm(
      "Clear Tenant Data",
      "Are you sure you want to clear all claim data for this tenant? This action cannot be undone."
    );

    if (result.isConfirmed) {
      try {
        await authFetch("/clear-tenant-data", {
          method: "POST",
          body: JSON.stringify({ tenantUuid }),
        });
        showAlert("Success", "Tenant data cleared successfully", "success");
        // Optionally, refresh the tenant list or update the UI
      } catch (errr) {
        handleError(errr);
      }
    }
  };

  const handleAddUser = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedTenant) return;
    try {
      await authFetch(`/tenants/users`, {
        method: "POST",
        body: JSON.stringify({
          tenant_uuid: selectedTenant.tenant_uuid,
          email: newUserEmail,
        }),
      });
      fetchTenantUsers(selectedTenant.tenant_uuid);
      setNewUserEmail("");
    } catch (er) {
      handleError(er);
    }
  };

  const handleRemoveUser = async (userUuid: string) => {
    if (!selectedTenant) return;
    try {
      await authFetch(`/tenants/users`, {
        method: "DELETE",
        body: JSON.stringify({
          tenant_uuid: selectedTenant.tenant_uuid,
          user_uuid: userUuid,
        }),
      });
      fetchTenantUsers(selectedTenant.tenant_uuid);
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <PageContainer>
      <PageTitle>Tenant Management</PageTitle>
      <ErrorDisplay error={error} onClose={clearError} />

      <Section>
        <SectionTitle>Create Tenant</SectionTitle>
        <Form onSubmit={handleCreateTenant}>
          <Input
            type="text"
            placeholder="Tenant Name"
            value={formData.tenant_name}
            onChange={(e) =>
              setFormData({ ...formData, tenant_name: e.target.value })
            }
          />
          <Input
            type="text"
            placeholder="Logo URL"
            value={formData.logo_url}
            onChange={(e) =>
              setFormData({ ...formData, logo_url: e.target.value })
            }
          />
          <Button type="submit">Create</Button>
        </Form>
      </Section>

      <Section>
        <SectionTitle>Tenants</SectionTitle>
        <Table>
          <thead>
            <tr>
              <Th>Name</Th>
              <Th>Logo URL</Th>
              <Th>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {tenants.map((tenant) => (
              <tr key={tenant.tenant_uuid}>
                <Td>{tenant.tenant_name}</Td>
                <Td>{tenant.logo_url}</Td>
                <Td>
                  <Button onClick={() => selectTenant(tenant.tenant_uuid)}>
                    Edit
                  </Button>
                  <Button
                    onClick={() => handleDeleteTenant(tenant.tenant_uuid)}
                  >
                    Delete
                  </Button>
                  <ClearDataButton
                    onClick={() => handleClearTenantData(tenant.tenant_uuid)}
                  >
                    Clear Claim Data
                  </ClearDataButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>

      {selectedTenant && (
        <Section>
          <SectionTitle>Edit Tenant</SectionTitle>
          <Form onSubmit={handleUpdateTenant}>
            <Input
              type="text"
              placeholder="Tenant Name"
              value={formData.tenant_name}
              onChange={(e) =>
                setFormData({ ...formData, tenant_name: e.target.value })
              }
            />
            <Input
              type="text"
              placeholder="Logo URL"
              value={formData.logo_url}
              onChange={(e) =>
                setFormData({ ...formData, logo_url: e.target.value })
              }
            />
            <Button type="submit">Update</Button>
          </Form>
        </Section>
      )}
      {selectedTenant && (
        <Section>
          <SectionTitle>Users for {selectedTenant.tenant_name}</SectionTitle>
          <Table>
            <thead>
              <tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Actions</Th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_uuid}>
                  <Td>{user.full_name}</Td>
                  <Td>{user.email}</Td>
                  <Td>
                    <Button onClick={() => handleRemoveUser(user.user_uuid)}>
                      Remove
                    </Button>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Form onSubmit={handleAddUser}>
            <Input
              type="email"
              placeholder="New User Email"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              required
            />
            <Button type="submit">Add User</Button>
          </Form>
        </Section>
      )}
    </PageContainer>
  );
};

export default TenantManagement;
