// components/ServicePayments.tsx
import React from "react";
import styled from "styled-components";
import { ServicePaymentsProps } from "../Constants/interfaces";

const ServicePaymentsContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  background: #f9f9f9;
  margin-bottom: 20px;
  display: block;
`;

const ServicePayment = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row;
  flex-wrap: wrap;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #ffffff;
  padding: 15px;
`;

const InfoContainer = styled.div`
  width: 100%;
`;
const HeaderLabel = styled.label`
  font-weight: bold;
  margin-right: 10px;
`;

const ServicePayments: React.FC<ServicePaymentsProps> = ({ claim }) => {
  if (!claim) {
    return (
      <ServicePaymentsContainer>
        Select a claim to view service payments
      </ServicePaymentsContainer>
    );
  }

  return (
    <ServicePaymentsContainer>
      <ServicePayment>
        <InfoContainer>
          <h3 style={{ marginTop: 0 }}>
            {claim.code} - {claim.code_explanation}
          </h3>
          <div>
            <HeaderLabel>Procedure Explanation:</HeaderLabel>
            <span>{claim.procedure_explanation}</span>
          </div>
          <div>
            <HeaderLabel>Charge Amount:</HeaderLabel>
            <span>${claim.charge_amount.toFixed(2)}</span>
          </div>
          <div>
            <HeaderLabel>Allowed Amount:</HeaderLabel>
            <span>${claim.allowed_amount.toFixed(2)}</span>
          </div>
          <div>
            <HeaderLabel>Paid Amount:</HeaderLabel>
            <span>${claim.paid_amount.toFixed(2)}</span>
          </div>
        </InfoContainer>
      </ServicePayment>
    </ServicePaymentsContainer>
  );
};

export default ServicePayments;
