// components/Claim835s.tsx
import React, { useState } from "react";
import styled from "styled-components";
import { ClaimDetailProps, statusTags } from "../Constants/interfaces";

const Claim835sContainer = styled.div`
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #f9f9f9;
`;

const InfoRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: column;
  flex-wrap: wrap;
`;

const InfoRow = styled.div`
  margin-bottom: 5px;
  max-width: 50%;
`;

const HeaderLabel = styled.label`
  font-weight: bold;
  margin-right: 10px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`;

const Tag = styled.span`
  background-color: #e0e0e0;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
`;

const RemoveTagButton = styled.button`
  background: none;
  border: none;
  color: #888;
  margin-left: 5px;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 1;
`;

const AddTagForm = styled.form`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const AddTagInput = styled.input`
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const AddTagButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
`;
const StatusTagDropdown = styled.select`
  padding: 5px;
  margin-top: 10px;
  width: 200px;
`;

const ClaimDetails: React.FC<ClaimDetailProps> = ({
  claim,
  onTagAdd,
  onTagRemove,
}) => {
  const [newTag, setNewTag] = useState("");

  if (!claim) {
    return (
      <Claim835sContainer>Select a claim to view details</Claim835sContainer>
    );
  }

  const handleAddTag = (e: React.FormEvent) => {
    if (!claim) return;
    e.preventDefault();
    if (newTag.trim()) {
      onTagAdd(claim.claim_h_uuid, newTag.trim());
      setNewTag("");
    }
  };
  const handleTagRemove = (cid: string, tag: string) => {
    onTagRemove(cid, tag);
    console.log("Removed Tag", tag);
    // setNewTag(tag); // force ui update
  };

  const handleStatusTagChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (!claim) return;
    const newStatus = e.target.value;
    // see if the claim already has a status tag
    try {
      // Remove old status tags

      for (let t = 0; t < statusTags.length; t++) {
        const tag = statusTags[t];
        if (claim?.tags.includes(tag)) {
          onTagRemove(claim?.claim_h_uuid, tag);
        }
      }

      // Add new status tag
      onTagAdd(claim?.claim_h_uuid, newStatus);
    } catch (error) {
      console.error("Error updating claim status:", error);
    }
  };

  const statusTag = claim.tags.find((tag) =>
    ["No Action Needed", "Needs Action", "Actioned", "Archived"].includes(tag)
  );

  return (
    <Claim835sContainer>
      <InfoRowContainer>
        <InfoRow>
          <h2>{claim.claim_name}</h2>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Claim Number:</HeaderLabel>
          <span>{claim.claim_number}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Patient Name:</HeaderLabel>
          <span>{claim.patient_name}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Status:</HeaderLabel>
          <span>{claim.claim_sub_status}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Payer:</HeaderLabel>
          <span>{claim.payer_name}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Total Claim Amount:</HeaderLabel>
          <span>${claim.charge_amount.toFixed(2)}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Allowed Amount:</HeaderLabel>
          <span>${claim.allowed_amount.toFixed(2)}</span>
        </InfoRow>
        <InfoRow>
          <HeaderLabel>Paid Amount:</HeaderLabel>
          <span>${claim.paid_amount.toFixed(2)}</span>
        </InfoRow>

        <InfoRow>
          <HeaderLabel>Date Of Service:</HeaderLabel>
          <span>{new Date(claim.procedure_date).toLocaleDateString()}</span>
        </InfoRow>
        {/* <InfoRow>
          <HeaderLabel>Payer:</HeaderLabel>
          <span>{claim.payer}</span>
        </InfoRow> */}
        <InfoRow>
          <HeaderLabel>Insurance Type:</HeaderLabel>
          <span>{claim.insurance_type}</span>
        </InfoRow>
      </InfoRowContainer>
      <StatusTagDropdown value={statusTag} onChange={handleStatusTagChange}>
        <option value="">Select Status Tag</option>
        <option value="No Action Needed">No Action Needed</option>
        <option value="Needs Action">Needs Action</option>
        <option value="Actioned">Actioned</option>
        <option value="Archived">Archived</option>
      </StatusTagDropdown>
      <TagContainer>
        {(claim.tags || [])
          .filter((t) => !statusTags.includes(t))
          .map((tag) => (
            <Tag key={tag}>
              {tag}
              <RemoveTagButton
                onClick={() => handleTagRemove(claim?.claim_h_uuid || "", tag)}
              >
                ×
              </RemoveTagButton>
            </Tag>
          ))}
      </TagContainer>
      <AddTagForm onSubmit={handleAddTag}>
        <AddTagInput
          type="text"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          placeholder="Add a new tag"
        />
        <AddTagButton type="submit">Add Tag</AddTagButton>
      </AddTagForm>
    </Claim835sContainer>
  );
};

export default ClaimDetails;
