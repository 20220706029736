import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import { authFetch } from "../../Utils/authFetch";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import ErrorDisplay from "../../components/ErrorDisplay";
import { showAlert } from "../../Utils/alerts";

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: "Arial", sans-serif;
`;

const PageTitle = styled.h1`
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  margin-top: 80px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 100px;
`;

const Button = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }
`;

const ColorPickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ColorPreview = styled.div<{ color: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  cursor: pointer;
`;

const TenantSettings: React.FC = () => {
  const [settings, setSettings] = useState({
    company_name: "",
    company_logo_url: "",
    company_primary_color: "#000000",
    company_secondary_color: "#ffffff",
    company_email_signature: "",
    company_notes: "",
    contact_info: "",
  });
  const [showPrimaryColorPicker, setShowPrimaryColorPicker] = useState(false);
  const [showSecondaryColorPicker, setShowSecondaryColorPicker] =
    useState(false);
  const { error, handleError, clearError } = useErrorHandler();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const data = await authFetch("/tenant-settings");
        setSettings(data);
      } catch (err) {
        handleError(err);
      }
    };
    fetchSettings();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handleColorChange = (color: any, type: "primary" | "secondary") => {
    setSettings((prev) => ({
      ...prev,
      [`company_${type}_color`]: color?.hex || "#000000",
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authFetch("/tenant-settings", {
        method: "PUT",
        body: JSON.stringify(settings),
      });
      showAlert("Success", "Settings updated successfully", "success");
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <PageContainer>
      <PageTitle>Company Settings</PageTitle>
      <ErrorDisplay error={error} onClose={clearError} />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Company Name</Label>
          <Input
            type="text"
            name="company_name"
            value={settings.company_name}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Company Logo URL</Label>
          <Input
            type="text"
            name="company_logo_url"
            value={settings.company_logo_url}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Primary Color</Label>
          <ColorPickerContainer>
            <ColorPreview
              color={settings.company_primary_color}
              onClick={() => setShowPrimaryColorPicker(!showPrimaryColorPicker)}
            />
            {showPrimaryColorPicker && (
              <SketchPicker
                color={settings.company_primary_color || "#000000"}
                onChange={(color) => handleColorChange(color, "primary")}
              />
            )}
          </ColorPickerContainer>
        </FormGroup>
        <FormGroup>
          <Label>Secondary Color</Label>
          <ColorPickerContainer>
            <ColorPreview
              color={settings.company_secondary_color}
              onClick={() =>
                setShowSecondaryColorPicker(!showSecondaryColorPicker)
              }
            />
            {showSecondaryColorPicker && (
              <SketchPicker
                color={settings.company_secondary_color || "#ffffff"}
                onChange={(color) => handleColorChange(color, "secondary")}
              />
            )}
          </ColorPickerContainer>
        </FormGroup>
        <FormGroup>
          <Label>Email Signature</Label>
          <TextArea
            name="company_email_signature"
            value={settings.company_email_signature}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Company Notes</Label>
          <TextArea
            name="company_notes"
            value={settings.company_notes}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Contact Information</Label>
          <TextArea
            name="contact_info"
            value={settings.contact_info}
            onChange={handleInputChange}
          />
        </FormGroup>
        <Button type="submit">Save Settings</Button>
      </Form>
    </PageContainer>
  );
};

export default TenantSettings;
